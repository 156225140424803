import React from "react";
import { AdminGraph } from "../hooks/adminGraph";
import { useState } from "react";
import { shortAddress, toLocale } from "../hooks/useContract";
import { CoinAddress, transactionUrl } from "../config";
import { formatUnits } from "viem";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Pagination } from "@mui/material";
import BlackListTable from "./BlacklistTable";
import ReportAnalytics from "./ReportAnalytics";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";
import { AnalyticsData } from "../hooks/useContractData";


const AdminReport = () => {
  const { address } = useAccount();
  const { adminAddress } = AnalyticsData();
  const validAdmin = adminAddress.some(
    (item) => item.toLowerCase() === address?.toLowerCase()
  );

  const [skip, setSkip] = useState(0);
  const { data } = AdminGraph(skip);

  // Table and admin data
  const tableData = data?.swappeds;
  const adminData = data?.adminActions;

  // For pagination
  const [dis, setDis] = useState(false);

  // For search and tab value
  const [value, setValue] = useState(0);

  // Handle pagination length
  const len = tableData?.length;

  // For changing the page
  const nextButton = () => {
    setSkip(skip + 10);
  };

  const pagechange2 = () => {
    if (skip >= 10) {
      setSkip(skip - 10);
      setDis(false);
    }
  };

  // For changing tab or some value
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (len < 10) {
      setDis(true);
    }
  }, [len]);

  //  Code for pagination
  const [pagination, setPagination] = useState(0);
  const length = adminData?.length;

  const [indexOfLast, setindexOfLast] = useState(10);
  const [indexOfFirst, setindexOfFirst] = useState(0);

  function pagechange(event, value) {
    const currentPage = Number(value);
    const dataPerPage = 10;

    const indexOfLastTodo = currentPage * dataPerPage;
    const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

    setindexOfFirst(indexOfFirstTodo);
    setindexOfLast(indexOfLastTodo);
  }
  const pageHandler = () => {
    const PageCount = Math.ceil(len / 10)
    if (!isNaN(PageCount)) { // Ensure PageCount is a valid number before setting it
      setPagination(PageCount);
    }
  }

  useEffect(() => {
    pageHandler();
  }, [length]);

  const [search, setSearch] = useState();
  const [filteredLocations, setFilteredLocations] = useState(tableData);

  useEffect(() => {
    if (search) {
      setFilteredLocations(
        tableData.filter((location) =>
          (location.user ?? "").toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredLocations(tableData);
    }
  }, [search, tableData]);

  const notify = () => toast.success("Copy Successfully");

  return (
    <>
      {/* {validAdmin ? */}
      <>
        <ReportAnalytics />
        <BlackListTable />

        <section
          className="boxsmain adminReportTable"
          style={{ marginTop: 40, marginBottom: 40 }}
        >
          <div className="os_container">
            <div style={{ marginBottom: "15px" }}>
              <Box
                sx={{
                  width: "100%",
                  bgcolor: "#000",
                  marginBottom: "50px",
                  marginTop: "30px",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  // centered
                  className="hello"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile="mobile"
                >
                  <Tab sx={{ color: "#fff" }} label="Swap History" />
                  <Tab sx={{ color: "#fff" }} label="Admin action log" />
                </Tabs>
              </Box>
            </div>
            {value === 0 && (
              <>
                <div className="searchbox flex justify-content-between align-items-center flex-row-reverse">
                  <form className="form" onSubmit={(e) => e.preventDefault()}>
                    <label htmlFor="search">
                      <input
                        className="input"
                        type="text"
                        required=""
                        placeholder="Wallet Address"
                        id="search"
                        onChange={(e) => setSearch(e.target.value)}
                        autoComplete="off"
                      />
                      <div className="fancy-bg" />
                      <div className="search">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                          </g>
                        </svg>
                      </div>
                      <button
                        className="close-btn"
                        type="reset"
                        onClick={() => setSearch()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </label>
                  </form>
                </div>
                <br />
                <div className="table-responsive">
                  <table className="table align-middle table-hover mb-0 dashboardTable">
                    <tbody />
                    <thead className="table-light">
                      <tr>
                        <th className="text-center">Sr No</th>
                        <th className="text-left">Wallet</th>
                        <th className="text-left">From</th>
                        <th className="text-left">To</th>
                        <th className="text-left">Amount Bought</th>
                        <th className="text-left">Amount Sold</th>
                        <th className="text-left">Transaction Hash</th>
                      </tr>
                    </thead>
                    <tbody />
                    {filteredLocations ? (
                      filteredLocations.map((row, i) => (
                        <>
                          <tr key={i}>
                            <td>{1 + skip + i}</td>
                            <CopyToClipboard text={row?.user}>
                              <td onClick={notify}>
                                {shortAddress(row.user)}
                                <CopyAllIcon
                                  style={{
                                    width: "17px",
                                    marginLeft: "3px",
                                    backgroundColor: "transparent",
                                    color: "white",
                                    padding: "0",
                                  }}
                                />
                              </td>
                            </CopyToClipboard>
                            <td>
                              {row.from?.toLowerCase() ===
                                CoinAddress.toLowerCase()
                                ? "ZEB"
                                : "USDT"}{" "}
                            </td>
                            <td>
                              {row.to?.toLowerCase() ===
                                CoinAddress.toLowerCase()
                                ? "ZEB"
                                : "USDT"}{" "}
                            </td>
                            <td>
                              {toLocale(
                                formatUnits(
                                  row?.amountOut,
                                  row.to?.toLowerCase() ===
                                    CoinAddress.toLowerCase()
                                    ? 18
                                    : 6
                                )
                              )}
                            </td>
                            <td>
                              {toLocale(
                                formatUnits(
                                  row?.amountIn,
                                  row.from?.toLowerCase() ===
                                    CoinAddress.toLowerCase()
                                    ? 18
                                    : 6
                                )
                              )}
                            </td>
                            <td>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#fff",
                                  background: "transparent",
                                }}
                                to={transactionUrl + row?.transactionHash}
                                target="blank"
                              >
                                {shortAddress(row?.transactionHash)}{" "}
                                <LaunchIcon
                                  sx={{
                                    width: "16px",
                                    marginLeft: "3px",
                                    color: "#2c70b3",
                                  }}
                                />
                              </Link>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                  </table>
                  {skip === 0 &&
                    <div className="p-5 text-center">
                      <h4>No Data Found</h4>
                    </div>}
                </div>
                <div className="prevbtn">
                  {skip === 0 ||
                    <button onClick={pagechange2}>&#10094; Prev</button>}
                  {dis ? (
                    ""
                  ) : (
                    <button onClick={nextButton} disabled={dis}>
                      Next &#10095;
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
          {value === 1 && (
            <>
              <div className="os_container">
                <div className="table-responsive">

                  <table className="table align-middle table-hover mb-0 dashboardTable">
                    <tbody />
                    <thead className="table-light   ">
                      <tr>
                        <th className="text-center">Sr No</th>
                        <th className="text-left">Admin</th>
                        <th className="text-left">Action</th>
                        <th className="text-left">TransactionHash</th>
                      </tr>
                    </thead>
                    <tbody />
                    {adminData ? (
                      adminData
                        .slice(indexOfFirst, indexOfLast)
                        .map((row, i) => (
                          <>
                            <tr key={i}>
                              <td>{i + indexOfFirst + 1}</td>
                              <CopyToClipboard text={row?.admin}>
                                <td onClick={notify}>{shortAddress(row?.admin)}
                                  <CopyAllIcon
                                    style={{
                                      width: "17px",
                                      marginLeft: "3px",
                                      backgroundColor: "transparent",
                                      color: "white",
                                      padding: "0",
                                    }}
                                  />
                                </td>
                              </CopyToClipboard>
                              <td>{row.actions}</td>
                              <td>
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "#fff",
                                    background: "transparent",
                                  }}
                                  to={transactionUrl + row?.transactionHash}
                                  target="blank"
                                >

                                  {shortAddress(row.transactionHash)}
                                  <LaunchIcon
                                    sx={{
                                      width: "16px",
                                      marginLeft: "3px",
                                      color: "#2c70b3",
                                    }}
                                  />

                                </Link>
                              </td>
                            </tr>
                          </>
                        ))
                    ) : (
                      <></>
                    )}
                  </table>
                  {(adminData?.length === 0 || adminData === undefined)  &&
                    <div className="p-5 text-center">
                      <h4>No Data Found</h4>
                    </div>}
                </div>
              </div>
              {pagination <= 1 ? (
                ""
              ) : (
                <>
                  <Pagination
                    className="myPagination"
                    onChange={pagechange}
                    count={pagination}
                    color="secondary"
                  />
                </>
              )}
            </>
          )}
        </section>
      </>

      {/* : <div className="titleh2">
          <h2>Not Admin</h2>
        </div>} */}
    </>
  );
};

export default AdminReport;
