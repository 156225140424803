import { toLocale } from "../hooks/useContract"
import { SwapRate } from "../hooks/useContractData"

export default function ReportAnalytics() {
    
    const { sellPrice, buyPrice, zebBalance, usdtBalance } = SwapRate()


    return(<>
        <section className="boxsmain" style={{ marginTop: 0, marginBottom: 40 }}>
        <div className="os_container">
          <div className="titleh2">
            <h2>Analytics </h2>
          </div>
          <div className="os_row">
            <div className="col4">
              <div className="cardbox">
                <h2>{toLocale(zebBalance)}</h2>
                <p>ZEB Liquidity</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>{toLocale(usdtBalance)}</h2>
                <p>USDT Liquidity</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>1 ZEB = {1 / buyPrice} USDT</h2>
                <p>ZEB Buy Price</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>1 ZEB = {sellPrice} USDT</h2>
                <p>ZEB Sell Price</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>)
}