import React from "react";
import '../Assets/css/Admin.css'
import Analytics from "./Analytics";
import { AdminFunction, AnalyticsData } from "../hooks/useContractData";
import { CoinAddress, USDT, apiSecret } from "../config";
import { useState } from "react";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";



const AdminPanel = () => {
  const [sell, setsell] = useState(0);
  const [buy, setBuy] = useState(0);
  const { address } = useAccount()

  const { feeAddress, adminFees, adminAddress, perSwapLimit } = AnalyticsData()

  const validAdmin = adminAddress.some(item => item.toLowerCase() === address?.toLowerCase())

  async function isEthereumAddress(address) {
    const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return ethereumAddressRegex.test(address);
  }
  

  const addToBlacklist = async (address) => {
    try {
      
      if (validAdmin === false) {
        
        toast.error("Not a Valid Admin")
      } else {

        const checkPoint = await isEthereumAddress(address)
        if (checkPoint === true) {
          const inputData = { address: address.toLowerCase() };

          const response = await fetch('https://zeb-node.vercel.app/add_12blacklist21', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer${apiSecret}`
            },
            body: JSON.stringify(inputData),
          });

          if (response) {

            const data = await response.json();
            

            if (data.success === true) {
              toast.success(data.msg)
            } else {
              toast.error(data.msg)
            }

          } else {
            

            console.error("Failed to get:", response);
          }
        }
        else {
          

          toast.error("Enter a valid Address")
        }
      }

    } catch (error) {
      

      console.error("Error posting data:", error);

      return false
    }
  };

  const removeFromBlacklist = async (address) => {
    try {
      
      if (validAdmin === false) {
        toast.error("Not a Valid Admin")
      } else {
        const checkPoint = await isEthereumAddress(address)
        if (checkPoint === true) {
          const inputData = { address: address.toLowerCase() };
          const response = await fetch('https://zeb-node.vercel.app/remove_123blacklist32', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer${apiSecret}`
            },
            body: JSON.stringify(inputData),
          });

          if (response) {
            const data = await response.json();
            

            if (data.acknowledged === true & data.deletedCount > 0) {
              toast.success("Whitelisted Successfully")
            }
            else {
              toast.error("Address is not in the Blacklist")
            }

          } else {
            console.error("Failed to save user:", response);
          }
        } else {
          

          toast.error("Enter a valid Address")
        }
      }
    } catch (error) {
      

      console.error("Error posting data:", error);
    }
  };

  const updateTradeVol = async (amount) => {
    
    try {
      if (validAdmin === false) {
        

        toast.error("Not a Valid Admin")
      } else {
        const inputData = { amount: Number(amount) };

        const response = await fetch('https://zeb-node.vercel.app/update_vol', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer${apiSecret}`
          },
          body: JSON.stringify(inputData),
        });

        if (response) {
          const data = await response.json();
          

          if (data.success === true) {
            toast.success(data.msg)
          } else {
            toast.error(data.msg)
          }

        } else {
          

          console.error("Failed to get:", response);
        }
      }

    } catch (error) {
      

      console.error("Error posting data:", error);

      return false
    }
  };

  const { setUsdtSwapRate, AddLiquidity, EmergencyWithdraw, EmergencyWithdrawAll, ChangeAdminFee, ChangeFeeAddress, ChangePerSwapMaxLimit, setZebSwapRate } = AdminFunction()

  return (
    <>
      {/* {validAdmin ? */}
      <>
        <section className="mainbox adminform">
          <div className="os_container">
            <div className="boxmain">
              <div className="boxinner">
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>Blacklist Address :</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      addToBlacklist(e.target.getValue.value)
                    }}>
                      <input type="text" name="getValue" autoComplete="off" placeholder="Enter Blacklist Address" />
                      <button type="submit" >Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>Whitelist Address :</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      removeFromBlacklist(e.target.getValue.value)

                    }}>
                      <input type="text" name="getValue" autoComplete="off" placeholder="Enter WhiteList Address" />
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>24h Trade Volume :</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      updateTradeVol(e.target.getValue.value)
                    }}>
                      <input type="text" name="getValue" autoComplete="off" placeholder="Enter Amount" />
                      <button type="submit" >Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>Add Liquidity</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      AddLiquidity(e.target.token.value, e.target.amount.value)
                    }}>
                      <input classname="inputswap" type="number" name="amount" autoComplete="off" placeholder="Enter Amount" />
                      <select className="inputswapbtn" name="token" id="">
                        <option value={CoinAddress}>ZEB</option>
                        <option value={USDT}>USDT</option>
                      </select>
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>Emergency Withdraw</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      EmergencyWithdraw(e.target.token.value, e.target.amount.value)
                    }}>

                      <input type="text" name="amount" autoComplete="off" placeholder="Enter Amount" />
                      <select className="inputswapbtn" name="token" id="">
                        <option value={CoinAddress}>ZEB</option>
                        <option value={USDT}>USDT</option>
                      </select>
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>Emergency (ALL)</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      EmergencyWithdrawAll(e.target.token.value)
                    }}>
                      <select className='Emergencydrop' name="token" id="">
                        <option value={CoinAddress}>ZEB</option>
                        <option value={USDT}>USDT</option>
                      </select>
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>Admin Fee (%): MIN (0.001%)</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      ChangeAdminFee(e.target.amount.value)
                    }}>
                      <input type="text" name="amount" autoComplete="off" placeholder={adminFees} />

                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>Change Fee Address</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      ChangeFeeAddress(e.target.address.value)
                    }}>
                      <input type="text" name="address" autoComplete="off" placeholder={feeAddress} />
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>Max Swap Limit</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      ChangePerSwapMaxLimit(e.target.limit.value)
                    }}>
                      <input type="text" name="limit" autoComplete="off" placeholder={perSwapLimit} />
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>Sell Price ( 1 ZEB = {sell} USDT ) :</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      setZebSwapRate(e.target.amount.value)
                    }}>
                      <input type="text" name="amount" autoComplete="off" placeholder="Enter Amount" onChange={(e) => setsell(e.target.value)} />
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
                <div className="os_row align-items-center boxpart">
                  <div className="leftbx">
                    <strong>BUY Price ( 1 ZEB = {buy} USDT ) :</strong>
                  </div>
                  <div className="inputbox">
                    <form onSubmit={(e) => {
                      e.preventDefault()
                      setUsdtSwapRate(e.target.amount.value)

                    }}>
                      <input type="text" name="amount" autoComplete="off" placeholder="Enter Amount" onChange={(e) => setBuy(e.target.value)} />
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Analytics />
      </>
      {/* : <div className="titleh2">
          <h2>Not Admin</h2>
        </div>} */}
    </>
  );
};

export default AdminPanel;
