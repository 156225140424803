import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Buffer } from 'buffer';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'sweetalert2/src/sweetalert2.scss';
import '../src/Assets/css/main.css';
import Layout from './Pages/Layout';
import Home from './Pages/Home';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from "react-hot-toast";
import { useAccount } from 'wagmi';
import AdminPanel from './Components/AdminPanel';
import AdminReport from './Components/AdminReport';

const AppRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='/2023-restrictedarea-admin' element={<AdminPanel />} />
        <Route path='/2023-restrictedarea-admin-report' element={<AdminReport />} />
      </Route>
    </Routes>
  )
}

const App = () => {

  const { address } = useAccount()
  window.Buffer = Buffer


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);

  }, [address]);




  return (
    <>
      {isLoading ? (
        <>
          <div className='loaderMain'>
            <div className="loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>

          </div>
        </>
      ) : (
        <>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
        </>
      )}
    </>
  );
}

export default App;
