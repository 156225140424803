import React from 'react'
import Banner from '../Components/Banner'
import Analyticsbox from '../Components/Analyticsbox'

const Home = () => {
    return (
        <>
            <Banner />
            <Analyticsbox />

        </>
    )
}

export default Home