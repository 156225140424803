import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useAccount } from "wagmi";

export const useGraphData = () => {
    const [data, setData] = useState([])
    const [tradeVolume, setTradeVol] = useState([])
    const { address } = useAccount()
    const fetchGraphData = useCallback(async () => {
        try {
            const now = Math.floor(Date.now() / 1000);
            const twentyFourHoursAgo = now - (24 * 60 * 60 );
            
            if (address) {

                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{
                            swappeds(where:{
                                user:"${address}"
                              },orderBy: blockTimestamp,  orderDirection: desc) {
                                user
                                from
                                to
                                amountIn
                                amountOut
                                swapRate
                                transactionHash
                              }
                              
                                tradeVolumes (where:{
                                    blockTimestamp_gte: "${twentyFourHoursAgo}",
                                    blockTimestamp_lte: "${now}"
                                  }) {
                                  USDT
                                  blockTimestamp
                                }
                              
                            }`
                    }
                };
                const response = await axios.request(options);
                const res = response.data.data;
                if (res.swappeds.length > 0) {

                    setData(res)

                } else {
                    setData([])
                }
                if (res.tradeVolumes.length > 0) {

                    setTradeVol(res.tradeVolumes)

                } else {
                    setTradeVol([])
                }

            }


        } catch (err) {
            console.error(err)
        }
    }, [address, setData])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 8000);

        return () => clearInterval(inter)
    }, [fetchGraphData, address])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData, address])

    return useMemo(() => {
        return {
            data,
            tradeVolume

        }
    }, [data,tradeVolume])
}


