import React from 'react'
import '../Assets/css/Admin.css'
import { SwapRate } from '../hooks/useContractData'
import { toLocale } from '../hooks/useContract'

const Analytics = () => {
  const { sellPrice, buyPrice, zebBalance, usdtBalance, tradeVol } = SwapRate()


  return (
    <>
      <section className="boxsmain">
        <div className="os_container">
          <div className="titleh2">
            <h2>Analytics </h2>
          </div>
          <div className="os_row">

            <div className="col4">
              <div className="cardbox">
                
                <h2> ${tradeVol}</h2>
                <p>24h Trade Volume</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>{toLocale(zebBalance)}</h2>
                <p>ZEB Balance</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>{toLocale(usdtBalance)}</h2>
                <p>USDT Balance</p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>{1 / buyPrice}</h2>
                <p>
                  Buy Price
                  <br />1 ZEB ~ {1 / buyPrice} USDT
                </p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <h2>{sellPrice}</h2>
                <p>
                  Sell Price <br />1 ZEB ~ {sellPrice} USDT
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Analytics