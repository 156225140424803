import React from "react";
import { useGraphData } from "../hooks/useGraph";
import { useState } from "react";
import { useEffect } from "react";
import { shortAddress, toLocale } from "../hooks/useContract";
import { Pagination } from "@mui/material";
import { formatUnits } from "viem";
import { CoinAddress, transactionUrl } from "../config";
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from "react-router-dom";
import { SwapRate } from "../hooks/useContractData";
import icon1 from "../Assets/images/swapping_Dinamic.png"
import icon2 from "../Assets/images/pool.png"
import icon3 from "../Assets/images/amm.png"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";


const getDisplayData = (tableData, sortField, sortDirection, showZebOnTop) => {
  let data = [...(tableData || [])]; // Start with the raw data

  // Apply sorting
  if (sortField) {
    data.sort((a, b) => {
      switch (sortField) {
        case 'amountBought':
          return amountBought(a, b, sortDirection);
        case 'amountSold':
          return amountSold(a, b, sortDirection);
        default:
          return 0;
      }
    });
    return data
  } else {

    // If you want to apply the showZebOnTop filter ONLY after sorting:
    if (showZebOnTop === true) {
      data = [...data.filter(row => row.from.toLowerCase() === CoinAddress.toLowerCase()),
      ...data.filter(row => row.from.toLowerCase() !== CoinAddress.toLowerCase())];
    } else if (showZebOnTop === false) {
      data = [...data.filter(row => row.from.toLowerCase() !== CoinAddress.toLowerCase()),
      ...data.filter(row => row.from.toLowerCase() === CoinAddress.toLowerCase())];
    }

    return data;
  };
}

const amountBought = (a, b, direction) => {
  const valueA = formatUnits(a.amountOut, a.to.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6);
  const valueB = formatUnits(b.amountOut, b.to.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6);
  return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
};

const amountSold = (a, b, direction) => {
  const valueA = formatUnits(a.amountIn, a.from.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6);
  const valueB = formatUnits(b.amountIn, b.from.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6);
  return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
};

const Analyticsbox = () => {
  const { data, tradeVolume } = useGraphData()

  const calculateTotal = () => {

    const stakedAmounts = tradeVolume.map(item => Number(formatUnits(item.USDT, 6)));

    const totalStakedAmount = stakedAmounts.reduce((acc, curr) => acc + curr, 0);

    return totalStakedAmount;
  };

  const swapVolume = calculateTotal()

  const tableData = data?.swappeds
  const { sellPrice, buyPrice, tradeVol } = SwapRate()


  //  Code for pagination 
  const [pagination, setPagination] = useState(0);
  const len = tableData?.length || 0

  const [indexOfLast, setindexOfLast] = useState(10)
  const [indexOfFirst, setindexOfFirst] = useState(0)
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' for ascending and 'desc' for descending
  const [sortField, setSortField] = useState(''); // column/field to sort by
  const [showZebOnTop, setShowZebOnTop] = useState();

  function pagechange(event, value) {
    const currentPage = Number(value)
    const dataPerPage = 10

    const indexOfLastTodo = currentPage * dataPerPage;
    const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

    setindexOfFirst(indexOfFirstTodo)
    setindexOfLast(indexOfLastTodo)
  }
  const pageHandler = () => {
    const PageCount = Math.ceil(len / 10)
    if (!isNaN(PageCount)) { // Ensure PageCount is a valid number before setting it
      setPagination(PageCount);
    }
  }

  useEffect(() => {
    pageHandler()
  }, [len])



  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };


  const handleFromClick = () => {
    setSortField()
    setShowZebOnTop(prev => !prev);
  };




  // Now use getDisplayData in your rendering:
  const displayData = getDisplayData(tableData, sortField, sortDirection, showZebOnTop);

  return (
    <>
      <section className="boxsmain">
        <div className="os_container">
          <div className="titleh2">
            <h2>Analytics </h2>
          </div>
          <div className="os_row">
            <div className="col4">
              <div className="cardbox">
                <div className="iconbox">
                  <img src={icon1} alt="" />
                </div>
                <h2>{sellPrice}</h2>
                <p>
                  Sell Price
                  <br />1 ZEB ~ {toLocale(sellPrice)} USDT
                </p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <div className="iconbox">
                  <img src={icon2} alt="" />
                </div>
                <h2>{toLocale(1 / buyPrice)}</h2>
                <p>
                  Buy Price
                  <br />1 ZEB ~ {toLocale(1 / buyPrice)} USDT
                </p>
              </div>
            </div>
            <div className="col4">
              <div className="cardbox">
                <div className="iconbox">
                  <img src={icon3} alt="" />
                </div>
                <h2>${tradeVol + swapVolume}</h2>
                <p>24h Trade Volume</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="boxsmain adminReportTable"

        >
          <div className="os_container">
            <div className="titleh2">
              <h2>Swap History </h2>
            </div>
            <div className="table-responsive">
              <table className="table align-middle table-hover mb-0 dashboardTable">
                <tbody></tbody>
                <thead className="table-light">
                  <tr>
                    <th className="text-center">Sr No</th>
                    <th className="text-left" onClick={handleFromClick}>
                      From
                      {showZebOnTop ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </th>

                    <th className="text-left">To</th>
                    <th className="text-left" onClick={() => handleSort('amountBought')}>Amount Bought
                      {sortField === 'amountBought' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </th>
                    <th className="text-left" onClick={() => handleSort('amountSold')}>Amount Sold
                      {sortField === 'amountSold' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </th>
                    <th className="text-left">Transaction</th>
                  </tr>
                </thead>
                <tbody>
                  {displayData ? displayData.slice(indexOfFirst, indexOfLast).map((row, i) => (
                    < >
                      <tr>
                        <td>{i + indexOfFirst + 1}</td>
                        <td>{((row.from).toLowerCase() === (CoinAddress).toLowerCase() ? "ZEB" : "USDT")} </td>
                        <td>{((row.to).toLowerCase() === (CoinAddress).toLowerCase() ? "ZEB" : "USDT")} </td>
                        <td>{toLocale(formatUnits(row.amountOut, (row.to).toLowerCase() === (CoinAddress).toLowerCase() ? 18 : 6))}</td>
                        <td>{toLocale(formatUnits(row.amountIn, (row.from).toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6))}</td>
                        <td>
                          <Link style={{ textDecoration: "none", color: '#fff' }} to={transactionUrl + row?.transactionHash} target="blank">
                            {shortAddress(row.transactionHash)} <LaunchIcon sx={{ width: "16px", marginLeft: "3px", color: "#2c70b3" }} />
                          </Link>
                        </td>
                      </tr>
                    </>
                  )) :
                    <>
                    </>
                  }
                </tbody>
              </table>
              {len > 0 ? "" :
                <h5 className="text-center mt-5 mb-5" >No data found</h5>}
            </div>
          </div>
        </div>
      </section>
      {pagination <= 1 ?
        ''
        : <>
          <Pagination className='myPagination' onChange={pagechange} count={pagination} color="primary" />
        </>
      }
    </>
  );
};

export default Analyticsbox;
