import React, { useRef } from "react";
import {
  CButton,
  CHeader,
  CHeaderBrand,
  CContainer,
} from "@coreui/react";
import logo from "../Assets/images/zebswap_v8.1.png";
import LazyLoad from "react-lazyload";
import { useAccount } from "wagmi";
import { useConnect, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { defaultNetwork } from "../config";
import { shortAddress } from "../hooks/useContract";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { toast } from "react-hot-toast"
import CopyToClipboard from 'react-copy-to-clipboard';



const Header = () => {
  const headerelement = useRef(null);
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const { chain } = useNetwork()


  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: defaultNetwork
  })

  async function disconnectwallet() {
    localStorage.removeItem('wagmi.injected.shimDisconnect')
    window.localStorage.clear()
    window.dispatchEvent(new Event("storage"));
  }

  const handleConnectWalletClick = () => {
    connect();
    localStorage.setItem('wagmi.injected.shimDisconnect', true);
    if (!window.ethereum) {
      toast.error('No wallet detected!');
    }
  };
  const notify = () => toast.success("Copy Successfully")


  return (
    <>
      <header ref={headerelement}>
        <CHeader className="headerbrand">
          <CContainer>
            <div className="  d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
              <CHeaderBrand to="/">
                <div className="header_logo">
                  <a className="sticky_none" href="/">
                    <LazyLoad
                      className="logoMainHead"
                      once
                      placeholder={
                        <svg
                          className="loaderAnim"
                          version="1.1"
                          id="L9"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 100 100"
                          enableBackground="new 0 0 0 0"
                        >
                          <path
                            fill="#ffffff"
                            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          >
                            <animateTransform
                              attributeName="transform"
                              attributeType="XML"
                              type="rotate"
                              dur="1s"
                              from="0 50 50"
                              to="360 50 50"
                              repeatCount="indefinite"
                            />
                          </path>
                        </svg>
                      }
                    >
                      <img src={logo} alt="" />
                    </LazyLoad>
                  </a>
                </div>
              </CHeaderBrand>
              <div className="d-flex align-items-center " >
                <div>

                </div>
                {address &&
                  <>
                    {chain.id !== defaultNetwork &&
                      <li className="wnbtn me-2" onClick={() => {
                        switchNetworkAsync?.().then(() => { }).catch(() => { })
                      }} >
                        Wrong Network
                      </li>}
                  </>
                }

                {!address && (
                  <>
                    <a className="boxbtn2" onClick={() => {
                      handleConnectWalletClick()
                    }} >
                      Connect
                    </a>
                  </>
                )}
                {address && (
                  <div className="flex">
                    <CopyToClipboard text={address}>
                      <CButton className="btnMU" onClick={notify} >
                        <span>{shortAddress(address)}</span>
                      </CButton>
                    </CopyToClipboard>
                    <CButton className="btnMU" onClick={() => {
                      disconnectwallet()
                      disconnect()
                    }} style={{ marginLeft: '8px' }}>
                      <span className="d-none d-sm-block">Disconnect</span>
                      <ExitToAppIcon />
                    </CButton>
                  </div>
                )}
              </div>
            </div>
            {address &&
              <>
                {chain.id !== defaultNetwork &&
                  <li className="mobilebtn" onClick={() => {
                    switchNetworkAsync?.().then(() => { }).catch(() => { })
                  }} >
                    Wrong Network
                  </li>}
              </>
            }
          </CContainer>
        </CHeader>
      </header>
    </>
  );
};

export default Header;
