import { CContainer } from "@coreui/react";
import { polygonchainRPC } from "../config";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import gsap from "gsap";
import { Power3 } from "gsap";
import Cardswap from "./Cardswap";
import bannerimg from "../Assets/images/v3-comp.gif";

const Banner = () => {
  useEffect(() => {
    const tl = gsap.timeline({
      defaults: { duration: 1, ease: Power3.easeOut },
    });
    tl.from("#demo", { autoAlpha: 0 })
      .from(
        ".text-reveal .animation-1",
        { duration: 0.8, y: "100%", opacity: 0, ease: "power1.out" },
        "=+1"
      )
      .from(".text-reveal .animation-2", {
        duration: 0.8,
        y: "100%",
        opacity: 0,
        ease: "power1.out",
      })
      .from(".buttonGroup", {
        xPercent: -900,
        duration: 0.8,
        ease: "power2.out",
      })
      .from(".loader", {
        xPercent: 900,
        rotate: 360,
        duration: 0.8,
        ease: "power3.easeout",
      });
  }, []);

  const addMainnetToMetaMask = async () => {
    if (typeof window.ethereum === "undefined") {
      toast.error("Wallet Not Found");
      return;
    }
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x89", // BSC Testnet chainId in hexadecimal
            chainName: "Polygon",
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            rpcUrls: [polygonchainRPC],
            blockExplorerUrls: ["https://polygonscan.com/"],
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="banner">
        <CContainer>
          <div className="row align-items-center">
            <div className="col-12 col-lg-7">
              <div className="bannerimg">
                <img src={bannerimg} alt="" />
              </div>
              <div className="text-reveal">
                <h2>Swap Smarter, Not Harder</h2>
                <p>
                  Discover the most efficient and secure way to swap your Zeb
                  Tokens with USDT. With ZebSwap, enjoy low fees, high limits, and
                  a seamless user experience. Elevate your trading game today
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-5">

              <Cardswap />

            </div>
          </div>

        </CContainer>
      </section>
    </>
  );
};

export default Banner;
