import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useAccount } from "wagmi";

export const AdminGraph = (skip) => {
    const [data, setData] = useState([])
    const { address } = useAccount()

    const fetchGraphData = useCallback(async () => {
        try {
            if (address) {

                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{
                            swappeds( first: 10  skip: ${skip}  orderBy: blockTimestamp  orderDirection: desc) {
                                user
                                from
                                to
                                amountIn
                                amountOut
                                swapRate
                                transactionHash
                              }
                              
                              adminActions(orderBy: blockTimestamp  orderDirection: desc){
                                admin
                                actions
                                transactionHash
                              }
                              
                            }`
                    }
                };

                const response = await axios.request(options);
                const res = response.data.data;
                setData(res)

            }


        } catch (err) {
            console.error(err)
        }
    }, [address, setData, skip])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 6000);

        return () => clearInterval(inter)
    }, [fetchGraphData, address])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData, address])

    return useMemo(() => {
        return {
            data,
        }
    }, [data])
}


