import { useEffect, useState } from "react";
import { shortAddress } from "../hooks/useContract";
import toast from "react-hot-toast";
import { Pagination } from "@mui/material";
import { AnalyticsData } from "../hooks/useContractData";
import { useAccount } from "wagmi";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyToClipboard from 'react-copy-to-clipboard';
import { apiSecret } from "../config";

export default function BlackListTable() {
    const [blacklistData, setblacklistData] = useState([])

    const { address } = useAccount()
    const { adminAddress } = AnalyticsData()
    const validAdmin = adminAddress.some(item => item.toLowerCase() === address?.toLowerCase())


    //  Code for pagination 
    const [pagination, setPagination] = useState(0);
    let length = blacklistData?.length

    const [indexOfLast, setindexOfLast] = useState(10)
    const [indexOfFirst, setindexOfFirst] = useState(0)

    function pagechange(event, value) {
        const currentPage = Number(value)
        const dataPerPage = 10

        const indexOfLastTodo = currentPage * dataPerPage;
        const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

        setindexOfFirst(indexOfFirstTodo)
        setindexOfLast(indexOfLastTodo)
    }
    const pageHandler = () => {
        const PageCount = Math.ceil(length / 10)
        setPagination(PageCount)
    }

    useEffect(() => {
        pageHandler()
    }, [length])

    async function getBlacklist() {
        try {

            const response = await fetch('https://zeb-node.vercel.app/get_blacklist', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer${apiSecret}`
                },

            });

            if (response) {

                const data = await response.json();
                if (data?.data.length > 0) {
                    setblacklistData(data.data)
                } else {
                    setblacklistData([])
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const removeFromBlacklist = async (address) => {
        try {
            if (validAdmin === false) {
                toast.error("Not a Valid Admin")
            } else {
                const inputData = { address: address.toLowerCase() };
                const response = await fetch('https://zeb-node.vercel.app/remove_123blacklist32', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer${apiSecret}`

                    },
                    body: JSON.stringify(inputData),
                });

                if (response) {
                    const data = await response.json();
                    if (data.acknowledged === true & data.deletedCount > 0) {
                        getBlacklist();
                        toast.success("Removed Successfully")
                        // setDataRefreshed(true); // Set dataRefreshed to true

                    }
                    else {
                        toast.error("Address is not in the Blacklist")
                    }

                } else {
                    console.error("Failed to save user:", response);
                }
            }
        } catch (error) {
            console.error("Error posting data:", error);
        }
    };

    useEffect(() => {
        getBlacklist()
    }, [])




    const [search, setSearch] = useState();
    const [filteredLocations, setFilteredLocations] = useState(blacklistData);

    useEffect(() => {
        if (search) {
            setFilteredLocations(
                blacklistData.filter(location =>
                    (location.address ?? '').toLowerCase().includes(search.toLowerCase())
                )
            );
        } else {
            setFilteredLocations(blacklistData);
        }
    }, [search, blacklistData]);


    const notify = () => toast.success("Copy Successfully")

    return (<>
        <section className="adminReportTable">

            <div className="os_container">
                <div className="react-tabs" data-rttabs="true">
                    <ul className="react-tabs__tab-list" role="tablist">
                        <li
                            className="react-tabs__tab react-tabs__tab--selected"
                            role="tab"
                            id="tab:r0:0"
                            aria-selected="true"
                            aria-disabled="false"
                            aria-controls="panel:r0:0"
                            tabIndex={0}
                            data-rttab="true"
                        >
                            Black List Address
                        </li>
                    </ul>
                    <div className="searchbox flex justify-content-between align-items-center flex-row-reverse">
                        <form className="form" onSubmit={(e) => e.preventDefault()}>

                            <label htmlFor="search">
                                <input
                                    className="input"
                                    type="text"
                                    required=""
                                    placeholder="Wallet Address"
                                    id="search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    autoComplete='off'

                                />
                                <div className="fancy-bg" />
                                <div className="search">
                                    <svg
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
                                    >
                                        <g>
                                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                                        </g>
                                    </svg>
                                </div>
                                <button className="close-btn" type="reset" onClick={() => setSearch()}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </label>
                        </form>

                    </div>
                    <br />
                    <div
                        className="react-tabs__tab-panel react-tabs__tab-panel--selected"
                        role="tabpanel"
                        id="panel:r0:0"
                        aria-labelledby="tab:r0:0"
                    >
                        <div className="table-responsive">
                            <table className="table align-middle table-hover mb-0 dashboardTable">
                                <tbody />
                                <thead className="table-light">
                                    <tr>
                                        <th className="text-left" width="33%">
                                            Sr.No
                                        </th>
                                        <th className="text-left" width="34%">
                                            Address
                                        </th>
                                        <th className="text-left" width="33%">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody />
                                {filteredLocations ? filteredLocations.slice(indexOfFirst, indexOfLast).map((row, i) => (
                                    <tr>
                                        <td style={{ fontSize: "15px" }}> {indexOfFirst + i + 1}</td>
                                        <CopyToClipboard text={row?.address}>

                                            <td onClick={notify} style={{ fontSize: "15px" }}>{shortAddress(row.address)} <CopyAllIcon style={{ width: "17px", marginLeft: "3px", backgroundColor: "transparent", color: "white", padding: "0" }} /></td>
                                        </CopyToClipboard>
                                        <td ><button className='actionbtn' onClick={() => removeFromBlacklist(row.address)}>
                                            Remove </button></td>
                                    </tr>
                                )) : <></>}
                            </table>
                            {filteredLocations.length === 0 &&
                                <div className="p-5 text-center">
                                    <h4>No Data Found</h4>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {pagination <= 1 ?
            ''
            : <>
                <Pagination className='myPagination' onChange={pagechange} count={pagination} color="secondary" />
            </>
        }
    </>)
}