import { providers } from "ethers"
import Web3 from "web3"
import { InjectedConnector } from 'wagmi/connectors/injected'

// COIN  ADDRESS
export const CoinAddress = "0xe8b630986d88653e29622046929aD90C0e3FbdBd";  // ZEB TOKEN Mainnet
export const USDT = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"      // USDT TOKEN Mainnet


export const swapContract = "0x6E9B0dfE68EAaccCb03e9AC303bC1A938B2e7130" // Mainnet

export const apiSecret = process.env.REACT_APP_API_SECRET;

export const graphQlUrl = "https://api.studio.thegraph.com/query/55529/zebmoney-swap-thegraph/v3" //Mainnet

export const transactionUrl = "https://polygonscan.com/tx/" // mainnet



// export const chainRPC =  "https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"  // Mainnet link
export const chainRPC =  "https://polygon-rpc.com/"  // Mainnet link
export const defaultWeb3 = new Web3(chainRPC)
export const web3Provider = new providers.JsonRpcProvider(chainRPC);

export const defaultNetwork = 137
export const connector = new InjectedConnector()
export const polygonchainRPC = "https://polygon-rpc.com"


export const tokenDetails = [{ address: CoinAddress, label: "ZEB" },
{ address: USDT, label: "USDT" }
]






