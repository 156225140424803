import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import { NumericFormat } from "react-number-format";
import Box from "@mui/material/Box";
import zebmoney from "../Assets/images/WhatsApp Image 2023-08-28 at 16.32.53.jpg";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import {
  Alldata,
  AnalyticsData,
  Approve,
  CheckUser,
  Conversion,
  Swap,
} from "../hooks/useContractData";
import { toLocale } from "../hooks/useContract";
import toast from "react-hot-toast";
import { CoinAddress, USDT, defaultNetwork } from "../config";
import { addToken } from "../hooks/addToken";
import maticimg from "../Assets/images/Icons/USDT.png";
import { OutlinedInput } from "@material-ui/core";
import { useNetwork } from "wagmi";
import scrolldownbg from "../Assets/images/Icons/scrolldownbg.698d54d822fc1ccb8bb07df401c90d56.svg";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});
NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Cardswap = () => {
  const [values, setValues] = useState("");
  const [swapt, setswapt] = useState(false);
  const [changeVal, setchangeVal] = useState(true);
  const [dis, setDis] = useState(true);
  const [isSwapped, setIsSwapped] = useState(false);
  const [hidebtn, sethidebtn] = useState(false);
  const { chain } = useNetwork();
  const isCorrectNetwork = chain?.id === defaultNetwork;
  const { adminFees: addper, perSwapLimit } = AnalyticsData();

  const handleChange = (event) => {
    setValues(event.target.value);
  };

  const { canSwap } = CheckUser();

  const { execute: doswap } = Swap();
  const { zebBalance, usdtBalance, zebAllowance, usdtAllowance } = Alldata();
  const { execute: getValue, ZebToUsdt, UsdtToZeb, adminFees } = Conversion();
  const { execute: ApproveToken } = Approve(isSwapped ? USDT : CoinAddress);

  const swapAmount = useCallback(async () => {
    try {

      if (!canSwap) return;
      
      if (values > 0) {
        const amount = values;
        setswapt(true);

        const promist = await doswap?.(
          isSwapped === false ? CoinAddress : USDT,
          isSwapped === false ? USDT : CoinAddress,
          String(amount)
        );
        setswapt(false);
      } else {
        toast.error("Please fill the amount")
      }
    } catch (err) {
      setswapt(false);
      toast.error(
        err.reason ? err.reason : err.data ? err.data.message : err.message
      );
    }
  }, [doswap, values, isSwapped, canSwap]);

  const convert = useCallback(
    async (e, token) => {
      try {
        await getValue(token, String(e));
      } catch (err) {
        console.error(err);

        toast.error(
          err.reason ? err.reason : err.data ? err.data.message : err.message
        );
      }
    },
    [getValue]
  );

  const swap = () => {
    setchangeVal(!changeVal);
    if (isSwapped === true) {

      setValues(UsdtToZeb);
      convert(UsdtToZeb, CoinAddress);
    } else {
      setValues(ZebToUsdt);
      convert(ZebToUsdt, USDT);
    }
    setIsSwapped(!isSwapped);
    setDis(!dis);
  };

  useEffect(() => {
    if (isSwapped === true) {
      sethidebtn(Number(usdtAllowance) > 0);
    } else {
      sethidebtn(Number(zebAllowance) > 0);
    }
  }, [isSwapped, usdtAllowance, zebAllowance]);

  return (
    <>
      <div className="swapcontainer">
        <div className="card-inner w-100">
          <h1
            className="text-center text-capitalize fw-bold swapheader"
          >
            Swap
          </h1>
          <div className={`box ${isSwapped ? "swapChange" : ""}`}>
            <div className="box1 ">
              <div className="icontext d-flex justify-content-between align-items-center my-2">
                <div className="wrap d-flex gap-2">
                  <img src={zebmoney} height="30px" width="30px" alt="" />
                  <h1>{"ZEB"}</h1>
                </div>
                <div
                  className="link"
                  onClick={() => {
                    addToken({
                      data: {
                        address: CoinAddress,
                        symbol: "ZEB",
                        decimals: 18,
                        name: "ZEB",
                      },
                    });
                  }}
                >
                  Add token
                </div>
              </div>
              <div className="balance">
                <span> from </span>
                <span className="d-flex ">
                  <span className=" ms-0">Balance:</span>
                  <span>{toLocale(zebBalance)}</span>
                </span>
              </div>

              <Box
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  placeholder="Enter amount"
                  type="number"
                  value={changeVal ? values : UsdtToZeb}
                  onKeyDown={(e) => {
                    const allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "ArrowLeft", "ArrowRight", "Delete","."];
                    if (!allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    convert(e.target.value, CoinAddress);
                  }}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  disabled={dis === false ? true : false}
                />
              </Box>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="animcirclemain my-2"
            >
              <div className="animcircle">
                <img
                  src={scrolldownbg}
                  width="120"
                  height="120"
                  alt="animation sroll"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 150 150"
                  version="1.1"
                >
                  <path id="textPath" d="M 0,75 a 75,75 0 1,1 0,1 z"></path>
                  <text>
                    <textPath href="#textPath">Zebswap * Zebswap *</textPath>
                  </text>
                </svg>
              </div>
              <div className="circlewrap">
                <div className="circle">
                  <SwapHorizontalCircleIcon className="iconswap" onClick={swap} />
                </div>
              </div>
            </div>
            <div className="box2">
              <div className="iconimg2 d-flex justify-content-between align-items-center my-2">
                <div className="wrap d-flex gap-2">
                  <img src={maticimg} height="30px" width="30px" alt="" />
                  <h1>{"USDT"}</h1>
                </div>
                <div
                  className="link"
                  onClick={() => {
                    addToken({
                      data: {
                        address: USDT,
                        symbol: "USDT",
                        decimals: 6,
                        name: "USDT",
                      },
                    });
                  }}
                >
                  Add token
                </div>
              </div>
              <div className="balance">
                <span>To </span>
                <span className="d-flex justify-content-between">
                  <span className=" ms-0">Balance:</span>
                  <span>{toLocale(usdtBalance)}</span>
                </span>
              </div>
              <Box
                className=" zabinput "
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  placeholder="Enter amount"
                  type="number"
                  value={changeVal ? ZebToUsdt : values}
                  onKeyDown={(e) => {
                    const allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "ArrowLeft", "ArrowRight", "Delete","."];
                    if (!allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                      handleChange(e);
                      convert(e.target.value, USDT);
                  }}
                  name="numberformat"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  disabled={dis}
                />
              </Box>
            </div>
          </div>
          <div className="texttittle mt-3">
            <span className="my-1">Admin Fess : {addper}%</span>
            <span className="my-1">
              Swap Fee :{" "}
              {Number(adminFees) === 0
                ? 0
                : Number(toLocale(adminFees)) === 0
                  ? "less than 0.001"
                  : toLocale(adminFees)}{" "}
              {isSwapped ? "USDT" : "ZEB"}
            </span>
            <span className="my-1">Per swap Maxlimit: {perSwapLimit}</span>
          </div>
          <div className=" d-flex flex-wrap justify-content-center gap-1 gap-lg-3 mt-3 mb-5">
            <div className="btnswap">
              <a onClick={(e) => {
                if (isCorrectNetwork) {
                  if (swapt) {
                    e.preventDefault();
                  } else {
                    swapAmount();
                  }
                }else{
                  toast.error("Please connect wallet")
                }
              }}>
                {swapt ? "Swaping" : "SWAP"}
              </a>
            </div>
            <div className="btnswap">
              {hidebtn !== true &&
                <a
                  onClick={(e) => {
                    if (isCorrectNetwork) {
                      ApproveToken();
                    } else {
                      toast.error("Please connect wallet")
                      e.preventDefault();
                    }
                  }
                  }
                >
                  Approve {isSwapped ? "USDT" : "ZEB"}
                </a>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Cardswap;
