import { ethers } from 'ethers'
import { CoinAddress, USDT, apiSecret, defaultNetwork, swapContract } from '../config'
import SwapAbi from "../abi/swapAbi.json"
import ERCAbi from "../abi/erc20.json"
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount, useNetwork } from "wagmi";
import { shortAddress, useContractInstance } from './useContract'
import { createPublicClient, formatUnits, http, parseUnits } from 'viem'
import { toast } from "react-hot-toast"

const polygonMumbai = {
    id: 80001,
    name: "Polygon Mumbai",
    network: "maticmum",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mumbai.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mumbai.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mumbai.infura.io/v3"],
            webSocket: ["wss://polygon-mumbai.infura.io/ws/v3"],
        },
        default: {
            http: ["https://special-polished-lake.matic-testnet.quiknode.pro/241402282ea6046c7d4516d7ec9610cad44e9838/"],
        },
        public: {
            http: ["https://special-polished-lake.matic-testnet.quiknode.pro/241402282ea6046c7d4516d7ec9610cad44e9838/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
    testnet: true,
};

const polygon = {
    id: 137,
    name: "Polygon",
    network: "matic",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mainnet.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mainnet.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mainnet.infura.io/v3"],
            webSocket: ["wss://polygon-mainnet.infura.io/ws/v3"],
        },
        default: {
            // http: ["https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"],
            http: ["https://polygon-rpc.com/"],
        },
        public: {
            http: ["https://polygon-rpc.com/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
};



export const Approve = (tokenAdd) => {
    const { address } = useAccount()
    const Token = useContractInstance(tokenAdd, ERCAbi, address !== undefined && address !== null)

    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await Token.increaseAllowance(swapContract, ethers.constants.MaxUint256);
                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Approving...",
                    success: "Approved Successfully",
                    error: "Failed to Approve",
                });
                toast.dismiss(loadingToast);
                resolve(true)

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [Token])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


}


export const CheckUser = () => {

    const { address } = useAccount()
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork;
    const [canSwap, setcanSwap] = useState(false)

    const addToBlacklist = useCallback(async () => {
        try {
            const inputData = { address: address.toLowerCase() };

            const response = await fetch('https://zeb-node.vercel.app/check_blacklist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer${apiSecret}`

                },
                body: JSON.stringify(inputData),
            });

            if (response) {
                const data = await response.json();
                if (data.success === true) {
                    setcanSwap(true)
                } else {
                    setcanSwap(false)
                }

            } else {
                console.error("Failed to get:", response);
            }


        } catch (error) {
            console.error("Error posting data:", error);

        }

    }, [address])

    useEffect(() => {
        if (address && isCorrectNetwork) {
            addToBlacklist();
        }

    }, [addToBlacklist, address, isCorrectNetwork])

    return useMemo(() => {
        return {
            canSwap
        }
    }, [canSwap])
}


export const Swap = () => {
    const { address } = useAccount()
    const swapInt = useContractInstance(swapContract, SwapAbi, address !== undefined && address !== null)

    const execute = useCallback((TokenIn, Tokenout, amount) => {
        return new Promise(async (resolve, reject) => {
            try {
                const convertAmount = parseUnits(amount, TokenIn === CoinAddress ? 18 : 6)

                const txn = await swapInt.Swap(TokenIn, Tokenout, convertAmount)

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed to Swap",
                });
                toast.dismiss(loadingToast);
                resolve(true)

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [swapInt])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


}


export const Conversion = () => {
    const [ZebToUsdt, setZebToUsdt] = useState(0);
    const [UsdtToZeb, setUsdtToZeb] = useState(0);
    const [adminFees, setadminFees] = useState(0);
    const swapInt = useContractInstance(swapContract, SwapAbi)

    const execute = useCallback((token, amount) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (token === CoinAddress) {
                    const txn = await swapInt.ZebToUsdt(parseUnits(amount, 18));
                    setZebToUsdt(formatUnits(txn[0], 6))
                    setadminFees(formatUnits(txn[1], 18))
                } else {
                    const txn = await swapInt.UsdtToZeb(parseUnits(amount, 6));
                    setUsdtToZeb(formatUnits(txn[0], 18));
                    setadminFees(formatUnits(txn[1], 6))
                }

            } catch (err) {
                console.error(err);
                resolve(null)
            }
        })
    }, [swapInt])


    return useMemo(() => {
        return {
            execute,
            ZebToUsdt,
            UsdtToZeb,
            adminFees
        }
    }, [UsdtToZeb, ZebToUsdt, execute, adminFees])


}


export function Alldata(token) {
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork
    const { address } = useAccount()
    const [zebAllowance, setzebAllowance] = useState(0);
    const [usdtAllowance, setusdtAllowance] = useState(0);
    const [zebBalance, setzebBalance] = useState(0);
    const [usdtBalance, setusdtBalance] = useState(0);

    const fetchHFGTokenInfo = useCallback(async () => {
        try {

            const client = createPublicClient({
                chain: polygon,
                transport: http(),
            });
            const data = await client.multicall({
                contracts: [
                    {
                        address: CoinAddress,
                        abi: ERCAbi,
                        functionName: 'allowance',
                        args: [address, swapContract],
                    },
                    {
                        address: CoinAddress,
                        abi: ERCAbi,
                        functionName: 'balanceOf',
                        args: [address],
                    },
                    {
                        address: USDT,
                        abi: ERCAbi,
                        functionName: 'allowance',
                        args: [address, swapContract],
                    },

                    {
                        address: USDT,
                        abi: ERCAbi,
                        functionName: 'balanceOf',
                        args: [address],
                    },

                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                setzebAllowance(formatUnits(data[0], 18))
                setzebBalance(formatUnits(data[1], 18))
                setusdtAllowance(formatUnits(data[2], 6))
                setusdtBalance(formatUnits(data[3], 6))
            }

        } catch (err) {
            console.error(err)
        }
    }, [address])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (address && isCorrectNetwork) {
                fetchHFGTokenInfo()
            }

        }, 8000);
        return () => clearInterval(interval);
    }, [fetchHFGTokenInfo, address, isCorrectNetwork, token]);

    useEffect(() => {
        if (address && isCorrectNetwork) {

            fetchHFGTokenInfo();
        }

    }, [fetchHFGTokenInfo, address, isCorrectNetwork, token])


    return useMemo(() => {
        return {
            zebAllowance,
            zebBalance,
            usdtAllowance,
            usdtBalance
        }
    }, [zebBalance, usdtBalance, zebAllowance, usdtAllowance])

}


export function SwapRate() {
    const [sellPrice, setsellPrice] = useState(0);
    const [buyPrice, setbuyPrice] = useState(0);
    const [zebBalance, setzebBalance] = useState(0);
    const [usdtBalance, setusdtBalance] = useState(0);
    const [tradeVol, settradeVol] = useState(0);

    const fetchHFGTokenInfo = useCallback(async () => {
        try {

            const client = createPublicClient({
                chain: polygon,
                transport: http(),
            });
            const data = await client.multicall({
                contracts: [

                    {
                        address: swapContract,
                        abi: SwapAbi,
                        functionName: 'SwapTokens',
                        args: [0],
                    },
                    {
                        address: swapContract,
                        abi: SwapAbi,
                        functionName: 'SwapTokens',
                        args: [1],
                    },
                    {
                        address: CoinAddress,
                        abi: ERCAbi,
                        functionName: 'balanceOf',
                        args: [swapContract],
                    },
                    {
                        address: USDT,
                        abi: ERCAbi,
                        functionName: 'balanceOf',
                        args: [swapContract],
                    },



                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                setsellPrice(formatUnits(data[0][1], 4))
                setbuyPrice(formatUnits(data[1][1], 4))
                setzebBalance(formatUnits(data[2], 18))
                setusdtBalance(formatUnits(data[3], 6))
            }

        } catch (err) {
            console.error(err)
        }
    }, [])

    const getVolume = useCallback(async () => {
        try {

            const response = await fetch('https://zeb-node.vercel.app/get_vol', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer${apiSecret}`

                },
            });

            if (response) {
                const data = await response.json();
                settradeVol(data[0]?.amount)

            } else {
                console.error("Failed to get:", response);
            }

        } catch (error) {
            console.error("Error posting data:", error);

        }

    }, [])

    useEffect(() => {
        const interval = setInterval(async () => {
            fetchHFGTokenInfo()
            getVolume()

        }, 12000);
        return () => clearInterval(interval);
    }, [fetchHFGTokenInfo, getVolume]);

    useEffect(() => {

        fetchHFGTokenInfo();
        getVolume()


    }, [fetchHFGTokenInfo, getVolume])

    return useMemo(() => {
        return {
            sellPrice,
            buyPrice,
            zebBalance,
            usdtBalance,
            tradeVol
        }
    }, [sellPrice, tradeVol, buyPrice, zebBalance, usdtBalance])

}


export function AdminFunction() {
    const { address } = useAccount()

    const swapInt = useContractInstance(swapContract, SwapAbi, address !== undefined && address !== null)
    const zebToken = useContractInstance(CoinAddress, ERCAbi, address !== undefined && address !== null)
    const USDTToken = useContractInstance(USDT, ERCAbi, address !== undefined && address !== null)


    const AddLiquidity = useCallback((token, amount) => {
        return new Promise(async (resolve, reject) => {
            try {
                const isCoin = token.toLowerCase() === CoinAddress.toLowerCase();
                const decimalPlaces = isCoin ? 18 : 6;
                const targetToken = isCoin ? zebToken : USDTToken;
    
                const convertAmount = parseUnits(amount, decimalPlaces);
    
                const txn = await targetToken.transfer(swapContract, convertAmount);
    
                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);
                resolve(true);
            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message);
                resolve(null);
            }
        });
    }, [zebToken, USDTToken]);


    const EmergencyWithdraw = useCallback((token, amount) => {
        return new Promise(async (resolve, reject) => {
            try {
                const convertAmount = parseUnits(amount, token === CoinAddress ? 18 : 6)
                const txn = await swapInt.EmergencyWithdraw(token, convertAmount)

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [swapInt])

    const EmergencyWithdrawAll = useCallback((token) => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await swapInt.EmergencyWithdrawAll(token)

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [swapInt])

    const ChangeAdminFee = useCallback((amount) => {

        return new Promise(async (resolve, reject) => {
            try {
                if (amount < 0.001) {
                    toast.error("Min Amount 0.001")
                    return resolve(true)

                }
                const txn = await swapInt.ChangeAdminFee(parseUnits(amount, 3))

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [swapInt])

    const ChangeFeeAddress = useCallback((address) => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await swapInt.ChangeFeeAddress(address)

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [swapInt])

    const ChangePerSwapMaxLimit = useCallback((limit) => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await swapInt.ChangePerSwapMaxLimit(limit)

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [swapInt])

    const setZebSwapRate = useCallback((amount) => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await swapInt.setZebSwapRate(parseUnits(amount, 4))

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [swapInt])

    const setUsdtSwapRate = useCallback((amount) => {
        return new Promise(async (resolve, reject) => {
            try {
                const convertAmount = String(1 / Number(amount))
                if (convertAmount < 0.001) {
                    toast.error("Max Amount 1000")
                    return resolve(true)

                }
                const txn = await swapInt.setUsdtSwapRate(parseUnits(convertAmount, 4))

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [swapInt])


    return useMemo(() => {
        return {
            AddLiquidity,
            EmergencyWithdraw,
            EmergencyWithdrawAll,
            ChangeAdminFee,
            ChangeFeeAddress,
            ChangePerSwapMaxLimit,
            setZebSwapRate,
            setUsdtSwapRate
        }
    }, [AddLiquidity, EmergencyWithdraw, EmergencyWithdrawAll, ChangeAdminFee, ChangeFeeAddress, ChangePerSwapMaxLimit, setUsdtSwapRate, setZebSwapRate])


}


export function AnalyticsData() {
    const [adminFees, setadminFees] = useState(0);
    const [feeAddress, setFeeAddress] = useState();
    const [perSwapLimit, setperSwapLimit] = useState(0);
    const [adminAddress, setadminAddress] = useState([]);


    const fetchHFGTokenInfo = useCallback(async () => {
        try {

            const client = createPublicClient({
                chain: polygon,
                transport: http(),
            });
            const data = await client.multicall({
                contracts: [

                    {
                        address: swapContract,
                        abi: SwapAbi,
                        functionName: 'adminFee',
                        args: [],
                    },
                    {
                        address: swapContract,
                        abi: SwapAbi,
                        functionName: 'feeAddress',
                        args: [],
                    },
                    {
                        address: swapContract,
                        abi: SwapAbi,
                        functionName: 'perSwapLimit',
                        args: [],
                    },

                    {
                        address: swapContract,
                        abi: SwapAbi,
                        functionName: 'getHandler',
                        args: [],
                    },

                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                setadminFees(formatUnits(data[0], 3));
                setFeeAddress(shortAddress(data[1]))
                setperSwapLimit(parseFloat(data[2]))
                setadminAddress(data[3])

            }

        } catch (err) {
            console.error(err)
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(async () => {
            fetchHFGTokenInfo()

        }, 12000);
        return () => clearInterval(interval);
    }, [fetchHFGTokenInfo]);

    useEffect(() => {
        fetchHFGTokenInfo();

    }, [fetchHFGTokenInfo])


    return useMemo(() => {
        return {
            adminFees,
            feeAddress,
            perSwapLimit,
            adminAddress
        }
    }, [adminFees, feeAddress, adminAddress, perSwapLimit])

}

